<template>
  <div class="container page">
    <van-nav-bar :title="$t('my.task_record')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="main">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-empty v-if="list.length === 0" :description="$t('withdraw.empty_data')" />
        <div v-else class="item_list" v-for="(v,key) in list" :key="key">
          <div class="lottery_info">
            <van-image class="cover" :src="v.lottery.ico">
              <template v-slot:loading>
                <van-loading type="spinner"/>
              </template>
            </van-image>
            <span class="period-number">{{v.expect}}</span>
            <span class="period-number name">{{v.lottery.name}}</span>
          </div>
          <div class="recent">
            <div class="kuaisan-ball left">
              <div v-if="v.status === 0">
                <van-image class="res-img" :src="'img/lottery/shaizi/time.gif'">
                <template v-slot:loading>
                  <van-loading type="spinner"/>
                </template>
              </van-image>
              <van-image class="res-img" :src="'img/lottery/shaizi/time.gif'">
                <template v-slot:loading>
                  <van-loading type="spinner"/>
                </template>
              </van-image>
              <van-image class="res-img" :src="'img/lottery/shaizi/time.gif'">
                <template v-slot:loading>
                  <van-loading type="spinner"/>
                </template>
              </van-image>
              </div>
              <div v-else>
                <van-image class="res-img" :src=" v.status === 0 ? 'img/lottery/shaizi/time.gif' : 'img/lottery/shaizi/' + v.opencode[0] + '.png' " >
                <template v-slot:loading>
                  <van-loading type="spinner"/>
                </template>
              </van-image>
              <van-image class="res-img" :src=" v.status === 0 ? 'img/lottery/shaizi/time.gif' : 'img/lottery/shaizi/' + v.opencode[1] + '.png' " >
                <template v-slot:loading>
                  <van-loading type="spinner"/>
                </template>
              </van-image>
              <van-image class="res-img" :src=" v.status === 0 ? 'img/lottery/shaizi/time.gif' : 'img/lottery/shaizi/' + v.opencode[2] + '.png' " >
                <template v-slot:loading>
                  <van-loading type="spinner"/>
                </template>
              </van-image>
              </div>
              
              <span class="res-des middle">{{v.status === 0 ? 0 : v.opencode[0] + v.opencode[1] + v.opencode[2]}}</span>
              <span class="res-des middle">{{v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 11 && (v.opencode[0] + v.opencode[1] + v.opencode[2]) &lt;= 18 ? $t("reservation.big") : $t("reservation.small")}}</span>
              <span class="res-des middle">{{v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0 ? $t("reservation.double") : $t("reservation.single")}}</span>
            </div>
          </div>
          <div class="topInfo">
            <span v-if="v.status === 1" style="color: #07c160">{{v.status_text}}</span>
            <span v-else>{{v.status_text}}</span>
            <!--  -->
            <div class="box-outer">
              <!-- <span style="text-align: center;" class="topInfo-span">{{$t("reservation.money")}}<br> {{v.money}}</span>
              <span style="text-align: center;" class="topInfo-span">{{$t("reservation.money")}}<br> {{v.data[0].profit}}</span> -->
              <div style="text-align: center;" class="topInfo-span">{{$t("reservation.money")}}<br>{{v.money}}</div>
              <div style="text-align: center;" class="topInfo-span" :style="{color:v.is_win==1?'green !important':v.is_win==0?'black !important':'red !important',borderColor:v.is_win==1?'green !important':v.is_win==0?'black !important':'red !important'}">{{ v.is_win==1?$t("my.prize"):v.is_win==0?$t("my.lotterying"):$t("my.lose")}}<br>{{v.profit}}</div>
            </div>
          </div>
       <!--   <div class="topInfo">
            <span v-if="v.is_win === 1" style="color: #07c160">{{v.win_text}}</span>
            <span v-else >{{v.win_text}}</span>
            <span>金额：{{v.profit}}</span>
          </div> -->
          <div class="topInfo">
            <span>{{$t("reservation.type")}}：</span>
            <span>{{v.type}}</span>
          </div>
          <div class="time">
            <span>{{$t("reservation.order_time")}}：{{v.create_time}}</span>
          </div>
          <div class="time">
            <span>{{$t("reservation.settle_time")}}：{{v.update_time}}</span>
          </div>
        </div>
        <div style="padding:20px 10px">
          <van-pagination :prev-text="$t('last')" :next-text="$t('next')" v-model="page" :total-items="total" :page-count="perpage" @change="getNum" />
        </div>
       
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Pagination } from 'vant';

Vue.use(Pagination);
export default {
  data() {
    return {
      isLoading: true,
      list:[],
      page:0,
      total:1,
      perpage:1
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast(this.$t("reservation.refresh"));
        this.isLoading = false;
      }, 500);
    },
 
    getUserGameList(page){
      this.page=(page || 0) + 1
      this.$http({
        method: 'get',
        url: 'user_get_game_list',
        data:{
          pageNum:page || 0
        }
      }).then(res=>{
        if(res.code === 200){
          this.isLoading = false;
          this.list = res.data.list;
          this.total=res.data.page.totalRecords
          this.perpage=res.data.page.totalPages
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      }).catch(err=>{
        console.log(err);
      })
    }, 
    getNum(e){
      this.getUserGameList(e-1)
    },

  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserGameList();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #fff;
  font-size: 35px;
}

::v-deep .van-loading__text {
  color: #fff;
  font-size: 35px;
}
.container .main{
  position: relative;
  overflow: auto;
  height: 100%;
  padding: 0 10px;
}
.name{
  position: absolute;
  right: 0;
  top: 10px;
  border: 2px solid #F78CA0;
  border-right: 0;
  border-radius: 5px 0 0 5px;
  padding: 10px 50px;
  color: #F78CA0 !important;
  line-height: unset !important;
}
.item_list{
  padding: 15px 15px;
  margin: 30px 10px;
  background: #270505;
  border-radius: 10px;
  line-height: 60px;
  position: relative;
}

.item_list .topInfo span{
  flex: 1;
  font-size: 35px;
  font-weight: 700;
  color: #fff;
}
.item_list .topInfo .topInfo-span{
  font-size: 24px;
  // font-weight: 700;
  color: #F78CA0;
  border: 2px solid #F78CA0;
  border-radius:10px;
  padding: 10px 20px;
  // position: absolute;
  line-height: 40px;
  bottom: 20px;
  margin-top: 20px;
  // max-width: 140px;
}
.item_list .time span{
  flex: 1;
  font-size: 25px;
  font-weight: 500;
  color: #fff;
}
.box-outer{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: absolute;
  right: 10px;
  bottom: 20px;
  max-width: 350px;
}
// .time{
//   // text-align: center;
// }
.topInfo-span:nth-child(1){
  // float: right;
  left: 10px;
}
.topInfo-span:nth-child(2){
  // float: left;
  right: 10px;
  border: 2px solid rgb(254, 11, 11) !important;
  color: red !important;
  margin-left: 20px;
}
.item_list .desc span{
  font-size: 25px;
  font-weight: 700;
  color: #9b9b9b;
}
.item_list .cover{
  width: 60px;
  height: 60px;
  -o-object-fit: cover;
  object-fit: cover;
}
.item_list  .period-number{
  margin-left: 50px;
  // margin-right: 10px;
  // height: 50px;
  line-height: 60px;
  font-size: 35px;
  font-weight: 700;
  color: #fff;
}
.item_list .lottery_info{
  display: flex;
  // position: relative;
}
.recent {
  display: flex;
  align-items: center;
  height: 100px;
}
.kuaisan-ball .left{
  justify-content: flex-start;
}
.kuaisan-ball{
  flex: 1;
  display: flex;
  align-items: center;
}

.kuaisan-ball .res-img{
  width: 70px;
  height: 70px;
  margin-right: 30px;
}
.kuaisan-ball .res-des{
  font-weight: 700;
  text-align: center;
  color: #fff;
}
.kuaisan-ball .res-des.middle{
  width: 15%;
  font-size: 35px;
}
</style>
